/* Navbar */

.navbar {
  &.default-layout {
    font-family: $type-2;
    background: $blue-teal-gradient;
    transition: background $action-transition-duration $action-transition-timing-function;
    -webkit-transition: background $action-transition-duration $action-transition-timing-function;
    -moz-transition: background $action-transition-duration $action-transition-timing-function;
    -ms-transition: background $action-transition-duration $action-transition-timing-function;
    .navbar-brand-wrapper {
      transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
      -webkit-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
      -moz-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
      -ms-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
      background: $sidebar-light-bg;
      .sidebar-dark & {
        background: $sidebar-dark-bg;
      }
      width: $sidebar-width-lg;
      height: $navbar-height;
      .navbar-brand {
        color: $white;
        font-size: 1.5rem;
        line-height: 48px;
        margin-right: 0;
        padding: 0.25rem 0;
        @include display-flex;
        &:active,
        &:focus,
        &:hover {
          color: lighten(color(gray-dark), 10%);
        }
        img {
          width: calc(#{$sidebar-width-lg} - 70px);
          max-width: 100%;
          //height: 28px;
          margin: auto;
          vertical-align: middle;
        }
      }
      .brand-logo-mini {
        display: none;
        img {
          width: calc(#{$sidebar-width-icon} - 50px);
          max-width: 100%;
          height: 28px;
          margin: auto;
        }
      }
    }
    .navbar-menu-wrapper {
      transition: width $action-transition-duration $action-transition-timing-function;
      -webkit-transition: width $action-transition-duration $action-transition-timing-function;
      -moz-transition: width $action-transition-duration $action-transition-timing-function;
      -ms-transition: width $action-transition-duration $action-transition-timing-function;
      color: $white;
      padding-left: 15px;
      padding-right: 15px;
      width: calc(100% - #{$sidebar-width-lg});
      height: $navbar-height;
      @media (max-width: 991px) {
        width: auto;
      }
      .navbar-toggler {
        border: 0;
        color: inherit;
        &:not(.navbar-toggler-right) {
          @media (max-width: 991px) {
            display: none;
          }
        }
      }
      .navbar-text {
        font-size: $default-font-size;
      }
      .navbar-nav {
        flex-direction: row;
        align-items: center;
        .nav-item {
          margin-left: 1rem;
          margin-right: 1rem;
          .nav-link {
            color: inherit;
            font-size: $navbar-font-size;
            vertical-align: middle;
            @media (max-width: 767px) {
              margin-left: 0.5rem;
              margin-right: 0.5rem;
            }
            i {
              font-size: $navbar-icon-font-size;
              vertical-align: middle;
            }
            .profile-text {
              margin-right: 15px;
              .rtl & {
                margin-right: 0;
                margin-left: 15px;
              }
            }
            &.nav-btn {
              .btn {
                background: rgba($white, .1);
                padding: 0.75rem 1rem;
                color: $white;
              }
              &:after {
                display: none;
              }
            }
          }
          &.color-setting {
            i {
              font-size: 25px;
              vertical-align: text-top;
            }
          }
        }
        &.navbar-nav-right {
          @media (min-width: 992px) {
            margin-left: auto;
            .rtl & {
              margin-left: 0;
              margin-right: auto;
            }
          }
        }
        &.header-links {
          height: $navbar-height;
          padding-left: 2%;
          .nav-item {
            margin: 0;
            .nav-link {
              height: $navbar-height;
              font-size: $navbar-font-size;
              padding: 16px 25px;
              @include display-flex;
              @include align-items(center);
              i {
                margin-right: 10px;
                font-size: 21px;
                .rtl & {
                  margin-right: 0;
                  margin-left: 10px;
                }
              }
            }
            &.active {
              background: rgba($white, 0.13);
            }
          }
        }
      }
    }
  }
}

@media (max-width:991px) {
  .navbar {
    &.default-layout {
      flex-direction: row;
      .navbar-brand-wrapper {
        width: 75px;
        .brand-logo {
          display: none;
        }
        .brand-logo-mini {
          display: inline-block;
        }
      }
    }
  }
  .navbar-collapse {
    display: flex;
    margin-top: 0.5rem;
  }
}