/* Badges */
.badge {
  border-radius: 0.25rem;
  font-size: 0.65rem;
  font-weight: initial;
  line-height: 1;
  padding: 0.2rem 0.3rem;
  font-family: $type-1;
  font-weight: 600;
  &:empty{
    display: inline-block;
    min-width: 10px;
    min-height: 10px;
    padding: 0;
    margin-right: 10px;
    @include border-radius(100%);
    .rtl &{
      margin-left: 10px;
      margin-right: 0;
    }
  }

  &.badge-pill {
    border-radius: 10rem;
  }

  &.badge-fw {
    min-width: 70px;
  }

  &.badge-lg {
    padding: 0.4rem 0.5rem;
  }
}
/*Badge variations*/
@each $color, $value in $theme-colors {
  .badge-#{$color} {
    @include badge-variations($value);
  }
}
/*Badge inverse variations*/
@each $color, $value in $theme-colors {
  .badge-inverse-#{$color} {
    @include badge-inverse-variations($value);
  }
}
/*Badge outlined variations*/
@each $color, $value in $theme-colors {
  .badge-outline-#{$color} {
    @include badge-outline-variations($value);
  }
}
