// Functions
@function social-color($key: "twitter") {
  @return map-get($social-colors, $key);
}

// Social Color
@each $color,
$value in $social-colors {
  .text-#{$color} {
    @include text-color(social-color($color));
  }
}

@each $color,
$value in $social-colors {
  .bg-#{$color} {
    background: social-color($color);
  }
}