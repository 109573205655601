/* Fonts */

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');

$fa-font-path: "../fonts";
@import "font-awsome/solid.scss";
@import "font-awsome/regular.scss";
@import "font-awsome/brands.scss";
@import "font-awsome/_icons.scss";
@import "font-awsome/_animated.scss";
